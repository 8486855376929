<template>
  <v-container>
    <h2 class="mb-5">Gestión de citas</h2>
    <v-row justify="center">
      <v-col cols="3">
        <v-menu :close-on-content-click="false"
                min-width="auto"
                transition="scale-transition"
                v-model="mostrarFecha"
                offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                dense
                outlined
                v-model="formattedFecha"
                label="Seleccione una fecha"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="selectedFecha"
              landscape
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="mostrarFecha = false"
            >
              Cancelar
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="onChangeFecha"
            >
              Aceptar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="8">
        <select-sedes-component
            :default="this.$store.getters.user ? this.$store.getters.user.id_sede: null"
            @select="onSelectSede"
            label="Sede de entrega"
            where="entregas = 1"
            :disabled="this.$store.getters.user ? ($store.getters.user.perfil.peso !== $acceso.ADM) : false"/>
      </v-col>
    </v-row>

    <v-row justify="center" class="">
      <v-col cols="11">
        <table-component
            v-model="selectedRows"
            :itemsPerPage="15"
            showBuscador
            :headers="headers"
            :items="citas"
            :sortBy="['hora']"
            :sortDesc="[false]"
            @select="selectUsuario"
        />
      </v-col>
    </v-row>

    <v-row justify="end" class="">
      <v-col cols="6">
        <v-btn @click="onClickCancelar"
               :disabled="selectedRows.length <= 0 || selectedRows[0].pedido.estado.codigo !== $utils.EN_CURSO_SEDE"
               class=""
               color="primary"
               v-if="$store.getters.user.perfil.peso === $acceso.ADM">
          Cancelar cita
          <v-icon right dark>
            mdi-calendar-remove
          </v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn @click="onClickModificar"
               :disabled="selectedRows.length <= 0 || selectedRows[0].pedido.estado.codigo !== $utils.EN_CURSO_SEDE"
               class=""
               color="primary">
          Modificar cita
          <v-icon right dark>
            mdi-calendar-today
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <modal-calendar-component :sede="sede"
                              :fecha="fecha"
                              @close="onCloseModalCita"
                              ref="modalCal"/>

    <!-- modal confirmar cancelacion -->
    <dialog-component
        ref="dialog"
        :width="300"
        @close="onCloseDialog"
        titulo="Cancelar petición"
        yes="Si"
        visibleYes
        no="No"
        visibleNo
    >
      <template v-slot>
        <v-container>
          <h3 class="mt-3">¿Está seguro?</h3>
        </v-container>
      </template>
    </dialog-component>

    <!-- Overlay -->
    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          :size="60"
          :width="6"
          color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import SelectSedesComponent from "@/components/app/SelectSedesComponent";
import TableComponent from "@/components/app/TableComponent";
import Calendario from "@/apis/Calendario";
import ModalCalendarComponent from "@/components/citas/ModalCalendarComponent";
import DialogComponent from "@/components/app/DialogComponent";
import pedido from "@/apis/Pedido";

export default {
  name: "CitaView",
  components: {
    DialogComponent,
    ModalCalendarComponent,
    TableComponent,
    SelectSedesComponent
  },
  data() {
    return {
      overlay: false,
      selectedFecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      mostrarFecha: false,
      mostrarTabla: false,
      mostrarCal: false,
      selectedSede: null,
      selectedRows: [],
      headers: [
        {
          text: "Nombre",
          value: "pedido.usuario.name",
          width: "50%",
        },
        {
          text: "Dia",
          value: "fecha",
          width: "15%",
          formatter: value => {
            return this.$utils.fechaFormato(value, "DD/MM/YYYY")
          },
        },
        {
          text: "Hora",
          value: "hora",
          width: "15%",
        },
        {
          text: "Estado",
          value: "pedido.estado.descripcion",
          width: "20%",
        },
      ],
      citas: [],
    };
  },
  computed: {
    sede() {
      return this.selectedSede;
    },
    fecha() {
      return this.selectedFecha;
    },
    formattedFecha() {
      if (!this.selectedFecha)
        return '';
      return this.$utils.fechaFormato(this.selectedFecha, 'dddd D MMMM, YYYY');
    },
  },
  mounted() {
  },
  methods: {
    async cargaDatos() {
      this.overlay = true;
      this.citas = [];

      // Obtener todos los datos de las citas de la fecha seleccionada
      // para rellenar la tabla
      //
      try {
        const params = {
          where: "fecha = '" + this.selectedFecha + "'",
          eager: {
            pedido: "id_sede_entrega = " + this.selectedSede.id,
            "pedido.usuario": null,
            "pedido.estado": "codigo IN ('" +
                this.$utils.EN_CURSO_SEDE + "','" +
                this.$utils.FINALIZADO + "')",
          },
        };
        this.citas = await Calendario.getUsuariosCitas(params);
        this.selectedRows = [];
        this.overlay = false;
      } catch (e) {
        this.overlay = false;
        //this.$toast.error(e.message);
      }
    },
    onChangeFecha() {
      this.mostrarFecha = false;
      this.cargaDatos();
    },
    onSelectSede(value) {
      this.selectedSede = value;
      this.cargaDatos();
    },
    async onCloseModalCita(value) {
      this.overlay = true;
      try {
        await Calendario.updateCitas(value, this.selectedRows);

        await this.cargaDatos();

        this.overlay = false;
        this.selectedRows = [];
        this.$toast.success("Datos actualizados");
      } catch (e) {
        this.overlay = false;
        this.$toast.error(e.message);
      }
    },
    async onCloseDialog(value) {
      if (value !== 'YES') {
        return;
      }

      this.overlay = true;

      try {
        // Cancelar pedidos
        //
        let resp = await pedido.cancelPedido(this.selectedRows);

        await this.cargaDatos();

        this.selectedRows = [];

        this.overlay = false;
        this.$toast.success(resp.message);
      }
      catch (e) {
        this.overlay = false;
        this.$toast.error(e.message);
      }
    },
    onClickModificar() {
      this.$refs.modalCal.mostrar = true;
    },
    onClickCancelar() {
      this.$refs.dialog.mostrar = true;
    },
    selectUsuario(value) {
      this.selectedRows = [];
      this.selectedRows.push(value);
    },
  },
}
</script>

