<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="11">
                <banner-component :texto="textoBanner"></banner-component>
            </v-col>
        </v-row>

        <v-row justify="center" class="menu-row">
          <!--
            <menu-component icono="icono-aprobacion.png"
                            subtitulo="Gestión envíos a domicilio"
                            titulo="Aprobación"
                            :ruta="{name: 'aprobacion'}"
                            :peso="$acceso.ADM" />
          -->
            <menu-component icono="icono-datos-empleado.png"
                            subtitulo="Gestión datos empleado"
                            titulo="Empleado"
                            :ruta="{name: 'empleado'}"
                            :peso="$acceso.ENT+$acceso.ZEL+$acceso.ADM" />

            <menu-component icono="icono-citas.png"
                            subtitulo="Gestión de citas"
                            titulo="Citas"
                            :ruta="{name: 'cita'}"
                            :peso="$acceso.ADM+$acceso.ENT+$acceso.ZEL" />

            <menu-component icono="icono-seguimiento.png"
                            subtitulo="Gestión de entregas"
                            titulo="Seguimiento"
                            :ruta="{name: 'seguimiento'}"
                            :peso="$acceso.ADM+$acceso.ENT+$acceso.ZEL" />

            <menu-component icono="icono-renovar-terminal.png"
                            subtitulo="Petición cambio terminal"
                            titulo="Renovar"
                            :ruta="{name: 'renovar'}"
                            :peso="$acceso.ADM+$acceso.ENT+$acceso.USU"
                            v-if="!$store.getters.user.sinTerminalNuevo"/>

            <menu-component icono="icono-informe-administrador.png"
                            subtitulo="Estado terminales"
                            titulo="Informes"
                            :ruta="{name: 'informes'}"
                            :peso="$acceso.ADM" />

        </v-row>

        <cookie-message-component v-if="!$store.getters.cookie"></cookie-message-component>
    </v-container>
</template>

<script>
    // @ is an alias to /src
    import BannerComponent from "@/components/home/BannerComponent";
    import MenuComponent from "@/components/home/MenuComponent";
    import CookieMessageComponent from "@/components/app/CookieMessageComponent";

    export default {
        name: 'HomeView',
        components: {
            CookieMessageComponent,
            MenuComponent,
            BannerComponent,
        },
        data() {
            return {
                textoBanner: null,
            };
        },
        mounted() {
            if (this.$store.getters.user.perfil)
                this.textoBanner = this.$store.getters.user.perfil.home_banner;
        },
        methods: {
        }
    }
</script>

<style scoped>
    .menu-row {
        margin-left: 0%;
        margin-right: 0%;
        padding-top: 45px;
    }
</style>
