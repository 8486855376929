<template>
    <v-select
        v-model="selected"
        :items="sedes"
        item-text="descripcion"
        item-value="id"
        dense
        outlined
        :disabled="disabled"
        :label="label"
        @change="onSelect"
        class="v-list-item"
        placeholder="Seleccione una sede"
        return-object
        :menu-props="{ offsetY: true }"
    ></v-select>
</template>

<script>
    // JAF
    // Este componente emite un evento al padre con el objeto completo seleccionado
    // Si se informa :default se muestra por defecto el elemento indicado
    // default o no existe no se muestra ninguno por defecto
    //
    import Sede from "@/apis/Sede";

    export default {
        name: "SelectSedesComponent",
        props: {
            // Id que se mostrará por defecto si está en la lista
            default: {
                Number
            },
            // Etiqueta
            label: {
                String
            },
            // Clausula where para la query de carga de datos
            where: {
                type: String,
            },
            // Clausula order by para la ordenacion de los datos de la lista
            orderBy: {
                type: String,
                default () {
                    return "descripcion ASC";
                }
            },
            disabled: {
                type: Boolean,
            }
        },
        data() {
            return {
                selected: null,
                sedes: [],
            };
        },
        mounted() {
            this.getData();
        },
        watch: {
            default() {
                if(this.default=== null){
                    return;
                }
                this.selected = this.sedes.find((element) => element.id === this.default);
            },
        },
        methods: {
            async getData() {
                try {

                    if(this.default=== null){
                        return;
                    }
                    const params = {
                        where: this.where,
                        orderBy: this.orderBy
                    };
                    this.sedes = await Sede.getSede(params);
                    this.selected = this.sedes.find((element) => element.id === this.default);
                    if (this.selected)
                        this.$emit('select', this.selected);
                } catch (e) {
                    this.$toast.error(e.message);
                }
            },
            onSelect(value) {
                this.selected = value;
                this.$emit('select', this.selected);
            }
        }
    }
</script>

