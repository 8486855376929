<template>
  <v-container>
    <h2 class="mb-5">Informe de estado</h2>

    <EstadisticasComponent :items="pedidos" :excel_fields="excel_fields"/>

    <!-- Overlay -->
    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          :size="60"
          :width="6"
          color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import Empleado from "@/apis/Empleado";
import EstadisticasComponent from "@/views/informes/EstadisticasComponent";

export default {
    name: "InformeTerminalView",
    components: {
      EstadisticasComponent,
    },
    data() {
        return {
          excel_fields: {
              "Sede": "sede_entrega",
              "Sede pdte.": "sede_pendiente",
              "Sede en curso": "sede_en_curso",
              "Sede entregado": "sede_entregado",
              "Sede total": "sede_total",
              // "Domicilio pdte.": "domicilio_pendiente",
              // "Domicilio en curso": "domicilio_en_curso",
              // "Domicilio entregado": "domicilio_entregado",
              // "Domicilio cancelado": "domicilio_cancelado",
              // "Domicilio total": "domicilio_total",
              "Adquiere terminal": "adquiere_antiguo"
          },
          pedidos: [],
          overlay: false,
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async loadData() {
            this.overlay = true;

            this.pedidos = [];

            try {
                this.pedidos = await Empleado.getEstadisticas();
            } catch (e) {
                this.$toast.error(e.message);
            }
            this.overlay = false;
        },
    },
};
</script>

<style scoped>

</style>
