<template>
    <v-container>
        <v-card>
            <v-card-text >
                <v-row class="pl-3 subtitle-1" style="align-items:center">
                    <v-checkbox
                        @change="onChange"
                        v-model="datos.checkUso"
                    ></v-checkbox>
                    <div>
                        Acepto los <a @click="$refs.Terminos.mostrar = true">términos de uso</a>
                    </div>
                </v-row>
                <v-row class="pl-3 subtitle-1" style="align-items:center">
                    <v-checkbox
                        @change="onChange"
                        v-model="datos.checkPrivacidad"
                    ></v-checkbox>
                    <div>
                        Acepto la <a @click="$refs.Privacidad.mostrar = true">política de privacidad</a>
                    </div>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- Dialogos del checkbox -->
        <dialog-component ref="Terminos" :width="800" titulo="Términos de uso" yes="Cerrar" visibleYes>
            <template v-slot>
                <v-container>
                     <div v-html="datos.terminos"></div>
                </v-container>
            </template>
        </dialog-component>
        <dialog-component ref="Privacidad" :width="800" titulo="Política de privacidad" yes="Cerrar" visibleYes>
            <template v-slot>
                <v-container>
                    <div v-html="datos.privacidad"></div>
                </v-container>
            </template>
        </dialog-component>
    </v-container>
</template>

<script>
    import dialogComponent from "@/components/app/DialogComponent";
    import Referencia from "@/apis/Referencia";
    export default {
        name: "PoliticaComponent",
        components: {
            dialogComponent,
        },
        data() {
            return {
                datos: {
                    checkUso: false,
                    checkPrivacidad: false,
                    terminos:null,
                    privacidad:null,
                },
            };
        },
        mounted() {
            this.loadData();
        },
        methods: {
            onChange() {
                this.$emit('change', this.datos.checkPrivacidad && this.datos.checkUso);
            },
            async loadData() {
                let respuesta = await Referencia.getTable("ENVIO_DOMICILIO");
                respuesta.forEach(element => {
                    switch (element.codigo) {
                        case "TERMINOS":
                            this.datos.terminos = element.valor;
                            break;
                        case "PRIVACIDAD":
                            this.datos.privacidad = element.valor;
                            break;
                    }
                });
            }
        },
    }
</script>

