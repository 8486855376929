import Api from "../config/axios.config";

export default {
    /**
     * Obtener datos terminales
     *
     * Estructura params:
     *  {
     *      where: { <campo1>: <valor1>, <campo2>: <valor2>, <campo3>: <valor3>, ... }
     *      orderBy: { <campo>: 'ASC' }
     *      eager: { '<campo1>,<campo2>', ... }
     *  }
     *
     * Si el parametro es nulo se devuelve toda la tabla (cuidado con ésto!!)
     *
     * @param params
     * @returns resp.data
     * @throws err
     */
    async getTerminal(params = {}) {
        let resp = await Api.post('/api/get-terminal', {params: params});

        // Controlar el error...
        //
        if (resp.data.error) {
            let err = new Error(resp.data.error);
            err.code = resp.data.errorCode;
            throw err;
        }

        return resp.data;
    },

    /**
     * Obtener datos terminales usuarios
     *
     * @param params
     * @returns resp.data
     * @throws err
     */
    async getTerminalUsuario(params = {}) {
        let resp = await Api.post('/api/get-terminal-usuario', {params: params});

        // Controlar el error...
        //
        if (resp.data.error) {
            let err = new Error(resp.data.error);
            err.code = resp.data.errorCode;
            throw err;
        }

        return resp.data;
    },

    /**
     *
     * @param pedido
     * @returns {Promise<boolean>}
     */
    async validaTerminalImei(pedido) {
        let resp = await Api.post('/api/get-imei-terminal',
            {idTerminal: pedido.id_terminal_nuevo, imei: pedido.imei}
        );

        if (resp.data.error) {
            let err = new Error(resp.data.error);
            err.code = resp.data.errorCode;
            throw err;
        }

        return true;
    }
};
