<template>
    <v-dialog
        eager
        v-model="mostrar"
        max-width="700"
    >
        <calendar-component ref="calendar"
                            :sede="sede"
                            :fecha="fecha"
                            @select="onSelectCita"/>
    </v-dialog>
</template>

<script>
    import CalendarComponent from "@/components/citas/CalendarComponent";
    export default {
        name: "ModalCalendarComponent",
        components: {CalendarComponent},
        props: {
            sede: Object,
            fecha: String,
        },
        data() {
            return {
                mostrar: false,
            };
        },
        mounted() {
        },
        methods: {
            onSelectCita(value) {
                this.mostrar = false;
                this.$emit("close", value);
            },
           recargaSede() {
               this.$refs.calendar.getData();
           },
        },
    }
</script>

