import Api from "../config/axios.config";

export default {
    /**
     * Obtener datos de la tabla pedidos
     *
     * Estructura params:
     *  {id: 123, adquirir_antiguo: 1, envio_domicilio: 0, ...}  cualquier campo de la tabla
     *
     * Si el parametro es nulo se devuelve toda la tabla (cuidado con ésto!!)
     *
     * @param params
     * @returns {JSON} resp.data
     * @throws {Error} err
     */
    async getPedido(params = {}) {
        let resp = await Api.post('/api/get-pedido', {params: params});

        // Controlar el error...
        //
        if (resp.data.error) {
            let err = new Error(resp.data.error);
            err.code = resp.data.errorCode;
            throw err;
        }

        return resp.data;
    },

    /**
     * Guardar datos del nuevo pedido
     *
     * @param params
     * @returns resp
     * @throws err
     */
    async newPedido(params) {
        let resp = await Api.post('/api/new-pedido', {params: params});

        // Controlar el error...
        //
        if (resp.data.error) {
            let err = new Error(resp.data.error);
            err.code = resp.data.errorCode;
            throw err;
        }

        return resp.data;
    },

    /**
     * Eliminar pedido (marcar estado borrado)
     *
     * @param params
     * @returns resp
     * @throws err
     */
    async deletePedido(params){
        let resp = await Api.post('/api/delete-pedido', {params: params});

        // Controlar el error...
        //
        if (resp.data.error) {
            let err = new Error(resp.data.error);
            err.code = resp.data.errorCode;
            throw err;
        }

        return resp.data;
    },

    /**
     * Finalizar pedido con entrega en sede
     *
     * @param params
     * @returns resp
     * @throws err
     */
    async finalizarSeguimientoSede(params){
        let resp = await Api.post('/api/finalizar-pedido-sede', {params: params});

        // Controlar el error...
        //
        if (resp.data.error) {
            let err = new Error(resp.data.error);
            err.code = resp.data.errorCode;
            throw err;
        }

        return resp.data;
    },

    /**
     * Cambia el estado del pedido denegado o aprobado para envío a domicilio
     *
     * @param params
     * @returns resp.data
     * @throws err
     */
    async estadoEnvioPedido(params){
        let resp = await Api.post('/api/estado-envio-pedido', {params: params});

        // Controlar el error...
        //
        if (resp.data.error) {
            let err = new Error(resp.data.error);
            err.code = resp.data.errorCode;
            throw err;
        }

        return resp.data;
    },

    /**
     *
     * @returns {Promise<any>}
     * @param pedidos
     */
    async cancelPedido(pedidos){
        let resp = await Api.post('/api/cancel-pedido', {pedidos: pedidos});

        // Controlar el error...
        //
        if (resp.data.error) {
            let err = new Error(resp.data.error);
            err.code = resp.data.errorCode;
            throw err;
        }

        return resp.data;
    },
};
