<template>
    <v-container>
        <h2 class="mb-5">Gestión envíos a domicilio</h2>
        <v-container>
            <v-row justify="center">
                <v-col cols="10">
                    <table-component
                        :headers="headers"
                        :items="pedidos"
                        :cargando="cargando"
                        @select="filaSeleccionada"
                    />
                </v-col>
            </v-row>
        </v-container>

        <v-container>
            <v-row justify="center">
                <v-col cols="10">
                    <v-card>
                        <v-card-title class="subheading font-weight-bold">
                            Detalle solicitud
                        </v-card-title>
                        <v-row class="ma-0">
                            <v-text-field
                                :value="pedidoSeleccionado.usuario.name"
                                label="Nombre"
                                outlined
                                readonly
                                dense
                                filled
                                hide-details
                                class="pa-4"
                            ></v-text-field>

                            <v-text-field
                                :value="pedidoSeleccionado.usuario.email"
                                label="Correo electrónico"
                                outlined
                                readonly
                                dense
                                filled
                                hide-details
                                class="pa-4"
                            ></v-text-field>

                            <v-text-field
                                :value="pedidoSeleccionado.usuario.telefono"
                                label="Teléfono"
                                outlined
                                readonly
                                dense
                                filled
                                hide-details
                                class="pa-4 shrink"
                            ></v-text-field>
                            <v-text-field
                                :value="pedidoSeleccionado.motivo_domicilio_text"
                                label="Motivo solicitud"
                                outlined
                                readonly
                                dense
                                filled
                                hide-details
                                class="pa-4"
                            ></v-text-field>
                        </v-row>
                        <v-row class="ma-0">
                            <v-textarea
                                :value="pedidoSeleccionado.comentario"
                                label="Otros motivos"
                                rows="3"
                                outlined
                                readonly
                                dense
                                filled
                                auto-grow
                                hide-details
                                class="pa-4"
                            ></v-textarea>
                        </v-row>

                        <v-col
                            class="text-right pt-0 pb-3"
                            v-if="pendienteDeAprobacion"
                        >
                            <v-btn
                                @click="$refs.verificarAprobado.mostrar = true"
                                class="ma-2"
                                color="primary"
                            >
                                Aprobar
                            </v-btn>
                            <v-btn
                                @click="$refs.verificarDenegado.mostrar = true"
                                class="ma-2"
                                color="primary"
                            >
                                Denegar
                            </v-btn>
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-container v-if="this.rechazadoEnvio">
            <v-row justify="center">
                <v-col cols="10">
                    <v-card>
                        <v-card-title class="subheading font-weight-bold">
                            Motivo de rechazo
                        </v-card-title>
                        <v-row class="ma-0">
                            <v-textarea
                                :value="pedidoSeleccionado.motivo_rechazo"
                                label="Motivo"
                                rows="3"
                                outlined
                                readonly
                                dense
                                filled
                                auto-grow
                                hide-details
                                class="pa-4"
                            ></v-textarea>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <dialog-component
            ref="verificarAprobado"
            :width="500"
            titulo="Envío domicilio"
            yes="Aceptar"
            visibleYes
            no="Cancelar"
            visibleNo
            @close="aprobar"
        >
            <template v-slot>
                <v-container>
                    <h3>¿Desea aprobar la petición?</h3>
                </v-container>
            </template>
        </dialog-component>

        <dialog-component
            ref="verificarDenegado"
            :width="500"
            titulo="Envío domicilio"
            yes="Aceptar"
            visibleYes
            no="Cancelar"
            visibleNo
            @close="denegar"
            :comentario = comentarioRechazo
            tieneComentario
        >
            <template v-slot>
                <v-container>
                    <h3>¿Desea denegar la petición?</h3>
                </v-container>
                <v-textarea
                                v-model = comentarioRechazo
                                label="Motivo de rechazo"
                                outlined
                                dense
                                filled
                                autofocus
                                class="pa-4 shrink"
                                :rules="[rules.obligatorio]"
                                rows="3"
                            ></v-textarea>
            </template>
        </dialog-component>

        <!-- Overlay
        -->
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                :size="60"
                :width="6"
                color="primary"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import tableComponent from "@/components/app/TableComponent";
import dialogComponent from "@/components/app/DialogComponent";

import Pedido from "@/apis/Pedido";
import Referencia from "@/apis/Referencia";

export default {
    name: "AprobacionView",
    components: {
        tableComponent,
        dialogComponent,
    },
    data() {
        return {
            pedidos: [],
            headers: [
                {
                    text: "Solicitante",
                    //sortable: false,
                    //filterable: true,
                    value: "usuario.name",
                    width: "45%",
                },
                {
                    text: "Fecha solicitud",
                    value: "fecha_crea",
                    formatter: this.formatearFecha,
                    width: "25%",
                },
                {
                    text: "Estado",
                    value: "estado.descripcion",
                    width: "30%",
                },
            ],
            pedidoSeleccionado: {
                usuario: {
                    name: "",
                    email: "",
                    telefono: "",
                },
                motivo_domicilio_text: "",
                comentario: "",
            },
            pendienteDeAprobacion: false,
            rechazadoEnvio:false,
            cargando: false,
            overlay: false,
            comentarioRechazo:null,
            rules: {
                    obligatorio: value => !!value || 'Campo obligatorio',
            }
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async loadData() {
            this.cargando = true;

            this.pendienteDeAprobacion = false;
            this.rechazadoEnvio = false;
            this.pedidos = [];
            this.resetPedidoSeleccionado();

            try {
                const params = {
                    where: "envio_domicilio = 1",
                    orderBy: "fecha_crea DESC",
                    eager: {
                        usuario: null,
                        estado:
                            "codigo IN ('" +
                            this.$utils.PEND_APROBACION +
                            "','" +
                            this.$utils.RECHAZADO +
                            "')",
                    },
                };
                this.pedidos = await Pedido.getPedido(params);
            } catch (e) {
                this.$toast.error(e.message);
            }

            this.cargando = false;
        },
        resetPedidoSeleccionado() {
            this.pedidoSeleccionado.usuario = {
                name: "",
                email: "",
                telefono: "",
            };
            this.pedidoSeleccionado.motivo_domicilio_text = "";
            this.pedidoSeleccionado.comentario = "";
        },
        formatearFecha(value) {
            return this.$utils.fechaFormato(value, "DD/MM/YYYY");
        },
        async filaSeleccionada(value) {
            this.overlay = true;

            let auxvalue = value;

            try {
                auxvalue.motivo_domicilio_text = (
                    await Referencia.getValue(
                        "MOTIVO_DOMICILIO",
                        value.motivo_domicilio
                    )
                ).valor;
            } catch {
                auxvalue.motivo_domicilio_text = auxvalue.motivo_domicilio;
            }

            this.pedidoSeleccionado = auxvalue;

            this.pendienteDeAprobacion =
                this.pedidoSeleccionado.estado.codigo ===
                this.$utils.PEND_APROBACION;

            this.rechazadoEnvio =
                this.pedidoSeleccionado.estado.codigo ===
                this.$utils.RECHAZADO;

            this.comentarioRechazo = null;
            this.overlay = false;
        },
        async aprobar(value) {
            if (value === "NO") {
                return;
            }

            try {
                this.overlay = true;

                let params = {
                    idPedido: this.pedidoSeleccionado.id,
                    codigoEstado: this.$utils.EN_CURSO_ZELERIS,
                };
                await Pedido.estadoEnvioPedido(params);

                this.$toast.success("El pedido ha sido aprobado");
            } catch (e) {
                this.$toast.error(e.message);
            }
            this.overlay = false;
            await this.loadData();
        },
        async denegar(value) {
            if (value === "NO") {
                return;
            }

            try {
                this.overlay = true;

                let params = {
                    idPedido: this.pedidoSeleccionado.id,
                    motivoRechazo: this.comentarioRechazo,
                    codigoEstado: this.$utils.RECHAZADO,
                };
                await Pedido.estadoEnvioPedido(params);

                this.$toast.success("El pedido ha sido denegado");
            } catch (e) {
                this.$toast.error(e.message);
            }
            this.overlay = false;
            await this.loadData();
        },
    },
};
</script>

