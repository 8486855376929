import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store.config';
import Cookies from "js-cookie";
import Auth from "@/apis/Auth";
import HomeView from "@/views/HomeView";
import TestView from "@/views/test/TestView";
import LoginView from "@/views/LoginView";
import RenovarView from "@/views/RenovarView";
import SeguimientoView from "@/views/SeguimientoView";
import EmpleadoView from "@/views/EmpleadoView";
import CitaView from "@/views/CitaView";
import AprobacionView from "@/views/AprobacionView";
//import InformesView from "@/views/InformesView";
//import InformeTerminalView from "@/views/informes/InformeTerminalView";
//import InformeOtrosView from "@/views/informes/InformeOtrosView";
import InformeEstadoView from "@/views/informes/InformeEstadoView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/adfs/:id",
    name: "adfs",
    meta: { authOnly: false },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { authOnly: false }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { authOnly: true }
  },
  {
    path: "/logout",
    name: "logout",
    meta: { authOnly: true }
  },
  {
    path: '/renovar',
    name: 'renovar',
    component: RenovarView,
    meta: { authOnly: true }
  },
  {
    path: '/seguimiento',
    name: 'seguimiento',
    component: SeguimientoView,
    meta: { authOnly: true }
  },
  {
    path: '/empleado',
    name: 'empleado',
    component: EmpleadoView,
    meta: { authOnly: true }
  },
  {
    path: '/cita',
    name: 'cita',
    component: CitaView,
    meta: { authOnly: true }
  },
  {
    path: '/aprobacion',
    name: 'aprobacion',
    component: AprobacionView,
    meta: { authOnly: true }
  },
  {
    path: '/informes',
    name: 'informes',
    component: InformeEstadoView,
    meta: { authOnly: true }
  },
/*
  {
    path: '/informeTerminal',
    name: 'informeTerminal',
    component: InformeTerminalView,
    meta: { authOnly: true }
  },
  {
    path: '/informeOtros',
    name: 'informeOtros',
    component: InformeOtrosView,
    meta: { authOnly: true }
  },
*/
  {
    path: '/test',
    name: 'test',
    component: TestView,
    meta: { authOnly: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const NODE_ENV = 'production';   // production

router.beforeEach(async (to, from, next) => {
  // Se omite la ventana de login de la aplicacion.
  // Todos los usuarios pasan por el ADFS de PwC.
  // SOLO para el entorno de produccion!!
  //
  if (to.name === 'login' && NODE_ENV === 'production'){
    window.location.href = 'https://login.pwc.com/openam/saml2/jsp/idpSSOInit.jsp?metaAlias=/pwc/idp101&spEntityID=https://www.renuevatuterminal.com'
  }
  // Solo para ruta de respuesta de adfs
  //
  else if (to.name === 'adfs'){
    try{
      const resp = await Auth.login({email: atob(to.params.id), password: Auth.getAppPass()});
      if (resp.data.error){
        next({name: 'login'});
      }
      else {
        next({name: 'home'});
      }
    }
    catch (e) {
      console.log('Error...', e.message);
      next({name: 'login'});
    }
  }
  // cualquier otra ruta que requiere autenticacion
  //
  else if (to.matched.some(record => record.meta.authOnly)){
    // No está autenticado
    //
    if (!store.getters.auth){
      // Hay cookie?
      //
      if (Cookies.get("XSRF-TOKEN")){
        // Recuperar el usuario
        //
        let resp = await Auth.me();

        if (resp === null)
          next({name: "login"});
        else
          next();
      }
      else {
        // Redirigir al login
        //
        next({name: "login"});
      }
    }
    else {
      // Está autenticado
      //
      next();
    }
  }
  else {
    // No se requiere autenticacion
    //
    next();
  }
})

export default router;
