import store from "@/config/store.config";

let Acceso = {
    install(Vue) {
        Vue.prototype.$acceso = {
            /**
             * Peso administrador. 1
             */
            ADM: 1,
            /**
             * Peso gestor de entregas. 2
             */
            ENT: 2,
            /**
             * Peso usuario. 4
             */
            USU: 4,
            /**
             * Peso gestor de entregas Zeleris. 8
             */
            ZEL: 8,

            /**
             * Indica si un usuario tiene acceso permitido a una pantalla, componente, ...
             *
             * @param {int} valor
             */
            permitido: function (valor) {
                return (store.getters.user.perfil.peso & valor) > 0;
            },
        }
    },
}

export default Acceso;
