<template>
  <v-container>
    <h2 class="mb-5">
      Datos de empleados
    </h2>

    <!-- Tabla -->
    <v-row justify="center" class="">
      <v-col cols="11">
        <table-component :excel_fields="excel_fields"
                         :api="empleadoApi"
                         :showBuscador="true"
                         :headers="headers"
                         :items="usuarios"
                         @select="selectUsuario"
                         :itemsPerPage="5">
        </table-component>
      </v-col>
    </v-row>

    <!-- Datos Usuario -->
    <div ref="detallesUsuario">
      <v-row justify="center" class="pa-0">
        <v-col cols="11">
          <datos-usuario-component v-model="datosUsuario" :id-usuario=idUsuario></datos-usuario-component>
        </v-col>
      </v-row>
    </div>

    <!-- Descripcion Pedido -->
    <v-row justify="center" class="pa-0">
      <v-col cols="11">
        <v-card>
          <v-card-title>
            Detalles del pedido
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row class="row">

                <v-col cols="3" sm="3">
                  <v-text-field
                      hide-details
                      v-model="datosPedido.tipoEntrega"
                      label="Tipo de entrega"
                      outlined
                      dense
                      readonly
                      filled>
                  </v-text-field>
                </v-col>
                <v-col cols="2" sm="2">
                  <v-text-field
                      hide-details
                      v-model="datosPedido.fecha_crea"
                      label="Fecha solicitud"
                      outlined
                      dense
                      readonly
                      filled>
                  </v-text-field>
                </v-col>
                <v-col cols="2" sm="2">
                  <v-text-field
                      v-if="datosPedido.idTipoEntrega"
                      hide-details
                      v-model="datosPedido.fecha_envio"
                      label="Fecha envío"
                      outlined
                      dense
                      readonly
                      filled>
                  </v-text-field>

                  <v-text-field
                      v-else
                      hide-details
                      v-model="datosPedido.fecha_cita"
                      label="Fecha cita"
                      outlined
                      dense
                      readonly
                      filled>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                      hide-details
                      v-model="datosPedido.estadoPedido"
                      label="Estado del pedido"
                      outlined
                      dense
                      readonly
                      filled>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="row" v-if="!!datosPedido.sede">
                <v-col>
                  <v-text-field
                      hide-details
                      v-model="datosPedido.sede"
                      label="Sede de entrega"
                      outlined
                      dense
                      readonly
                      filled>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-col offset-md="9">
              <!-- Boton tramitar pedido -->
              <v-btn v-if="$store.getters.user && (esGestorEntregas() || esAdmin())"
                     :to="{ name: 'seguimiento', params: { param: datosPedido.id, sede: datosPedido.idSedeEntrega }}"
                     color="primary"
                     type="submit"
                     @click="scrollToTop">
                Tramitar cita
                <v-icon right dark>
                  mdi-cellphone-basic
                </v-icon>
              </v-btn>

              <!-- Boton crear nuevo pedido -->
              <v-btn v-if="$store.getters.user && puedeCrear()"
                     :to="{ name: 'renovar', params: { idUsuario: idUsuario }}"
                     color="primary"
                     type="submit"
                     @click="scrollToTop">
                Nueva cita
                <v-icon right dark>
                  mdi-calendar-edit
                </v-icon>
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Overlay -->
    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          :size="60"
          :width="6"
          color="primary"
      ></v-progress-circular>
    </v-overlay>

  </v-container>
</template>

<script>
import tableComponent from "@/components/app/TableComponent";
import DatosUsuarioComponent from "@/components/user/DatosUsuarioComponent";
import Usuario from "@/apis/Usuario";
import Empleado from "@/apis/Empleado";

export default {
  name: "EmpleadoView",
  components: {
    tableComponent,
    DatosUsuarioComponent,
  },
  data() {
    return {
      empleadoApi: Empleado.getDatosExcel(),
      excel_fields: {
        "Nombre": "name",
        "Correo electrónico": "email",
        "Teléfono": "telefono",
        "Entrega": "tipo_entrega",
        "Estado": "estado_pedido",
        "Sede entrega": "nombre_sede",
        "Fecha solicitud": "fecha_crea",
        "Fecha cita": "fecha_cita",
        "Fecha envío": "fecha_envio",
        "Adquiere antiguo": "adquiere_antiguo",
        "Marca antiguo": "marca_viejo",
        "Modelo antiguo": "modelo_viejo",
        "IMEI antiguo": "imei_viejo",
        "Marca nuevo": "marca_nuevo",
        "Modelo nuevo": "modelo_nuevo",
        "IMEI nuevo": "imei_nuevo",
        "Sede usuario": "nombre_sede_usuario"
      },
      usuarios: [],
      headers: [
        {
          text: "Solicitante",
          value: "name",
        },
        {
          text: "Estado",
          value: "estado_pedido",
          align: "center",
        },
        {
          text: "Correo electrónico",
          value: "email",
        },
        {
          text: "Sede usuario",
          value: "nombre_sede_usuario",
          width: "300px",
        },
      ],
      cargando: false,
      idUsuario: null,
      datosUsuario: {},
      datosPedido: {
        id: null,
        idTipoEntrega: null,
        tipoEntrega: null,
        fecha_crea: null,
        fecha_cita: null,
        estadoPedido: null,
        codigoEstado: null,
        idSedeEntrega: null,
        sede: null,
      },
      pedido: null,
      idPedidoParam: null,
      overlay: false,
      datosExcel: null,
    };
  },
  watch: {
    idUsuario(newValue) {
      return newValue;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.overlay = true;
      try {
        // Obtener los datos de empleados
        //
        const params = {};
        //Mostrar pedidos
        this.items = await Usuario.getUsuarios(params);
        this.usuarios = this.items;
      } catch (e) {
        console.log(e);
        this.overlay = false;
        this.$toast.error(e.message);
      }
      this.overlay = false;
    },
    selectUsuario(value) {
      this.overlay = true;
      try {
        if (value.id != this.idUsuario) {
          this.clearData();
        }
        this.idUsuario = value.id;

        //Datos en detalle del pedido
        //envio_domicilio 0:Sede, 1:Domicilio
        this.datosPedido.idTipoEntrega = value.envio_domicilio;
        this.datosPedido.tipoEntrega = this.formatearTipoEnvio(value.envio_domicilio);
        if (value.fecha_crea) {
          this.datosPedido.fecha_crea = this.$utils.fechaFormato(value.fecha_crea, "DD/MM/YYYY");
        }
        if (value.fecha_cita) {
          this.datosPedido.fecha_cita = this.$utils.fechaFormato(value.fecha_cita, "DD/MM/YYYY");
        }
        if (value.fecha_envio) {
          this.datosPedido.fecha_envio = this.$utils.fechaFormato(value.fecha_envio, "DD/MM/YYYY");
        }
        this.datosPedido.estadoPedido = value.estado_pedido;
        this.datosPedido.codigoEstado = value.estado_codigo;
        this.datosPedido.idSedeEntrega = value.id_sede_entrega;
        this.datosPedido.id = value.id_pedido;
        this.datosPedido.sede = value.nombre_sede;

        this.overlay = false;
      } catch (e) {
        console.log(e);
        this.overlay = false;
        this.$toast.error(e.message);
      }
    },
    scrollToTop() {
      //Scroll a top cuando redirigo con <router-link>
      window.scrollTo(0, 0);
    },
    esGestorEntregas() {
      return (this.datosPedido.codigoEstado === this.$utils.EN_CURSO_SEDE &&
          this.datosPedido.idSedeEntrega === this.$store.getters.user.id_sede &&
          this.$acceso.permitido(this.$acceso.ENT + this.$acceso.ZEL));
    },
    esAdmin() {
      return (this.datosPedido.codigoEstado === this.$utils.EN_CURSO_SEDE &&
          this.$acceso.permitido(this.$acceso.ADM));
    },
    puedeCrear() {
      return this.$acceso.permitido(this.$acceso.ADM)
          && this.idUsuario !== null
          && (this.datosPedido.id === null || this.datosPedido.codigoEstado === this.$utils.CANCELADO);
    },
    formatearTipoEnvio($tipoEnvio) {
      if ($tipoEnvio == 1) {
        return this.$t('tipo_envio.domicilio');
      }
      if ($tipoEnvio == 0) {
        return this.$t('tipo_envio.sede');
      }
      return null;
    },
    clearData() {
      this.datosPedido = {};
      this.datosUsuario = {};
    },
  },
}
</script>

