<template>
    <v-container class="pa-0">
        <v-sheet elevation="2">
            <v-toolbar flat>
                <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                    Hoy
                </v-btn>
                <v-btn @click="prev" color="grey darken-2" fab small text>
                    <v-icon small>
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title v-if="titulo">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="next" color="grey darken-2" fab small text>
                    <v-icon small>
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
            </v-toolbar>
        </v-sheet>
        <v-sheet :height="height" elevation="2">
            <v-calendar
                event-overlap-mode="column"
                :event-height="20"
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="citas"
                type="month"
                @click:event="showEvent"
            >
            </v-calendar>
            <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :position-x="100"
                :position-y="100"
            >
                <v-card
                    color="grey lighten-4"
                    flat
                >
                    <v-toolbar
                        :color="selectedEvent.color"
                        dark
                    >
                        <v-btn icon>
                            <v-icon>mdi-clock</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Seleccione horario día {{$utils.fechaFormato(selectedEvent.start,'D')}}
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="justify-center">
                        <v-container justify="center" >
                            <v-chip-group style="max-width: 600px"
                                column
                                mandatory
                                active-class="primary"
                                v-model="selectedHora"
                            >
                                <v-badge :content="horario.libre"
                                    :key="horario.hora"
                                    :value="horario.hora"
                                    :color="horario.libre > 0 ? 'green' : 'darkgray'"
                                    overlap
                                    style="margin-right: 20px; margin-top: 5px"
                                    v-for="horario in horarios"
                                >
                                    <v-chip :disabled="horario.libre <= 0 || !horario.activo">
                                        {{ horario.texto }}
                                    </v-chip>
                                </v-badge>
                            </v-chip-group>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            text
                            color="primary"
                            @click="confirmaCita"
                        >
                            Confirmar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="secondary"
                            @click="cancelarCita"
                        >
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </v-sheet>
    </v-container>
</template>

<script>
    import Calendario from "@/apis/Calendario";

    export default {
        name: "CalendarComponent",
        props: {
            sede: {
                type: Object
            },
            height: {
                type: Number,
                default: 500
            },
            width: {
                type: Number,
                default: 200
            },
            admin: {
                type: Boolean,
                default () {
                    return false;
                }
            },
            fecha: {
                type: String,
                default () {
                    return '';
                }
            },
        },
        data() {
            return {
                titulo: null,
                citas: [],
                selectedHora: null,
                horarios: [],
                result: {
                    fecha: null,
                    hora: null,
                    id_calendario: null
                },
                focus: this.fecha,
                selectedEvent: {},
                selectedElement: null,
                selectedOpen: false,
                events: [],
                colors: ['green', 'red'],
                names: ['Meeting', 'Holiday'],
            };
        },
        mounted () {
            this.$refs.calendar.checkChange();
            this.titulo = this.$refs.calendar.title;
        },
        watch: {
            // Watcher para el cambio de sede desde el padre
            sede() {
                this.getData();
            },
            fecha() {
                this.focus = this.fecha;
                this.getData();
            },
        },
        methods: {
            async getData() {
                try {
                    if (!this.sede)
                        return;

                    // Obtener todas las citas de la sede
                    //
                    let dadas = 0;
                    let totales = 0;
                    let libres = 0;

                    const citas = await Calendario.getCalendario(this.sede.id);
                    this.citas = [];

                    // Mapear los datos para completar el array para el componente
                    //
                    for (let i = 0; i < citas.length; i++){
                        dadas = citas[i].citas_dadas + citas[i].citas_dadas2;
                        totales = citas[i].citas_totales + citas[i].citas_totales2;
                        libres = totales - dadas < 0 ? 0 : totales - dadas;
                        this.citas.push({
                            id_calendario: citas[i].id_calendario,
                            name: libres + ' disponible',
                            start: citas[i].fecha,
                            color: this.color(totales, dadas),
                            timed: false,
                            disponibles: libres
                        });
                    }

                    // Te muestra por defecto la fecha en el calendario
                    //
                    if (this.fecha === '')
                        this.focus = this.sede !== null ? this.sede.fecha_desde : '';
                    else
                        this.focus = this.fecha;

                } catch (e) {
                    this.$toast.error(e.message);
                }
            },
            color(totales, dadas){
                if ((totales - dadas) <= 0)
                    return 'red';
                if (Math.round(dadas * 100 / totales) >= 75 || totales - dadas === 1)
                    return 'orange';

                return 'green';
            },
            setToday () {
                this.focus = '';
            },
            prev () {
                this.$refs.calendar.prev();
            },
            next () {
                this.$refs.calendar.next();
            },
            showEvent ({ nativeEvent, event }) {
                if (this.admin){
                    this.eventAdmin(event);
                }
                else {
                    this.eventUsuario(nativeEvent, event);
                }
            },
            eventUsuario(nativeEvent, event){
                if (event.disponibles <= 0){
                    this.$toast.error('No hay citas disponibles');
                    nativeEvent.stopPropagation();
                    return;
                }

                if (!this.$utils.fechaCorrectaCita(event.start)){
                    this.$toast.error('Fecha pasada');
                    nativeEvent.stopPropagation();
                    return;
                }

                this.open(nativeEvent, event)

                //nativeEvent.stopPropagation();
            },
            async open(nativeEvent, event) {
                this.selectedEvent = event;
                this.selectedElement = nativeEvent.target;
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));

                this.horarios = [];

                // Cargar informacion detallada de los horarios
                // Horas disponibles para la sede seleccionada
                //
                const citas = await Calendario.getCitas(this.sede.id, this.selectedEvent.id_calendario);
                for (let i = 0; i < citas.length; i++){
                    let desde = citas[i].hora;
                    let hasta = this.$utils.sumaHoras(citas[i].hora, 1);

                    if (citas[i].slot === 0)
                        hasta = citas[i].hora_hasta;

                    const libre = citas[i].citas_disponibles < 0 ? 0 : citas[i].citas_disponibles;

                    this.horarios.push({
                        texto: desde + " a " + hasta,
                        hora: desde,
                        libre: libre,
                        fecha: this.selectedEvent.start,
                        activo: (this.$utils.horaCorrectaCita(this.selectedEvent.start, desde) && libre > 0)
                            || (this.sede.slot === 0 && libre > 0)
                    });
                }

                this.selectedHora = null;

                // Ordenar el array de horas
                //
                this.horarios.sort( (a, b) => {
                    if(parseInt(a.hora) < parseInt(b.hora)) {
                        return -1;
                    }
                    if(parseInt(a.hora) > parseInt(b.hora)) {
                        return 1;
                    }
                    return 0;
                });
            },
            eventAdmin(event){
                this.$emit('select', event);
            },
            confirmaCita() {
                this.selectedOpen = false;

                // Resultado de la seleccion de cita
                //
                this.result.hora = this.horarios[this.selectedHora].hora;
                this.result.fecha = this.selectedEvent.start;
                this.result.id_calendario = this.selectedEvent.id_calendario;

                this.$emit('select', this.result);
            },
            cancelarCita(){
                this.getData();
                this.selectedOpen = false;
            },
        }
    }
</script>

