<template>
    <v-container>
        <v-form :disabled="disabled"
                ref="form"
        >
            <v-row justify="center">
                <v-col style="margin-bottom: -30px">
                    <v-select
                        v-model="selected"
                        :items="motivos"
                        item-text="valor"
                        item-value="codigo"
                        :rules="[rules.obligatorio]"
                        dense
                        outlined
                        :label="label"
                        @change="onSelect"
                        class="v-list-item"
                        placeholder="Seleccione un motivo"
                        return-object
                        :menu-props="{ offsetY: true }"
                    >
                    </v-select>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col>
                    <v-textarea
                        :rules="[comentarioObligatorio]"
                        auto-grow
                        clearable
                        clear-icon="mdi-close-circle"
                        outlined
                        label="Otros motivos"
                        v-model="otrosMotivos"
                        :disabled="disabledComentario"
                        rows="2"
                        @change="onChange"
                    ></v-textarea>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
    // JAF
    // Este componente emite un evento al padre con el objeto completo seleccionado
    // Si se informa :default se muestra por defecto el elemento indicado
    // Si no se informa :default o no existe no se muestra ninguno por defecto
    //
    import Referencia from "@/apis/Referencia";

    export default {
        name: "SelectMotivoEnvioComponent",
        props: {
            // Codigo que se mostrará por defecto si está en la lista
            default: {
                String
            },
            // Etiqueta
            label: {
                String
            },
            disabled: Boolean,
            value: Object,
        },
        data() {
            return {
                selected: null,
                otrosMotivos: '',
                motivos: [],
                rules: {
                    obligatorio: value => !!value || 'Campo obligatorio',
                },
            };
        },
        mounted() {
            this.otrosMotivos = this.value ? this.value.comentario : null;
            this.getData();
        },
        computed: {
            disabledComentario() {
                if (!this.selected)
                    return true;

                return (this.selected.codigo !== 'OT');
            },
        },
        methods: {
            async getData() {
                try {
                    let auxDefault = this.default;
                    if (this.value)
                        auxDefault = this.value.codigo;
                    this.motivos = await Referencia.getTable("MOTIVO_DOMICILIO");
                    this.selected = this.motivos.find((element) => element.codigo === auxDefault);
                    if (this.selected)
                        this.$emit('select', this.selected);
                } catch (e) {
                    this.$toast.error(e.message);
                }
            },
            onSelect(value) {
                this.selected = value;
                this.selected.comentario = '';
                this.otrosMotivos = '';
                this.$emit('select', this.selected);
            },
            onChange(value) {
                this.selected.comentario = value;
                this.$emit('select', this.selected);
            },
            validarMotivoEnvio() {
                return this.$refs.form.validate();
            },
            comentarioObligatorio(value) {
                if (!value && !!this.selected && this.selected.codigo === 'OT')
                    return 'Campo obligatorio';

                return true;
            },
        }
    }
</script>

<style>
    .v-list-item {
        text-align: start;
    }
</style>
