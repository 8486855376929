import Api from "../config/axios.config";

export default {
    /**
     * Obtener el calendario de una sede (totales)
     *
     * @param idSede
     * @returns resp.data
     * @throws err
     */
    async getCalendario(idSede) {
        let resp = await Api.post('/api/get-calendario', {idSede: idSede});

        // Controlar el error...
        //
        if (resp.data.error) {
            let err = new Error(resp.data.error);
            err.code = resp.data.errorCode;
            throw err;
        }

        return resp.data;
    },

    /**
     * Obtener las citas de un dia (id_calendario) en particular (detalle)
     *
     * @param idSede
     * @param idCal
     * @returns resp.data
     * @throws err
     */
    async getCitas(idSede, idCal) {
        let resp = await Api.post('/api/get-citas', {idSede: idSede, idCalendario: idCal});

        // Controlar el error...
        //
        if (resp.data.error) {
            let err = new Error(resp.data.error);
            err.code = resp.data.errorCode;
            throw err;
        }

        return resp.data;
    },

    /**
     * Obtener los datos de usuario y pedido para cada cita
     *
     * @param params
     * @returns resp.data
     * @throws err
     */
    async getUsuariosCitas(params = {}) {
        let resp = await Api.post('/api/get-usuarios-citas', {params: params});

        // Controlar el error...
        //
        if (resp.data.error) {
            let err = new Error(resp.data.error);
            err.code = resp.data.errorCode;
            throw err;
        }

        return resp.data;
    },

    /**
     * Modificar las citas de los pedidos informados
     *
     * @param nuevaCita
     * @param pedidos
     * @returns resp.data
     * @throws err
     */
    async updateCitas(nuevaCita, pedidos) {
        let resp = await Api.post('/api/update-citas', {nuevaCita: nuevaCita, pedidos: pedidos});

        // Controlar el error...
        //
        if (resp.data.error) {
            let err = new Error(resp.data.error);
            err.code = resp.data.errorCode;
            throw err;
        }

        return resp.data;
    },
};
