import axios from "axios";
import store from "@/config/store.config";

// Configuracion de cabeceras http
// Revisar los siguientes valores en caso de problemas con CORS:
//   'Access-Control-Allow-Origin': '*',
//   'Access-Control-Allow-Headers': 'lo que sea',
// PenTest:
//   'Cache-Control': 'no-cache, no-store, must-revalidate',
//
const Api = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    timeout: 60000,
    withCredentials: true,
    headers: {
        common: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    }
});

// Interceptor para inyectar el token en cada llamada al api
// 'Authorization': store.getters.token
// Se actualiza desde el store que se habrá obtenido en el login
//
Api.interceptors.request.use(
    (config) =>
    {
        // Solo si se ha recuperado token
        if (store.getters.token){
            config.headers.common['Authorization'] = `Bearer `+store.getters.token;
            //console.log('token...',store.getters.token);
        }

        return config;
    }
);

// Interceptor para obtener la version en cada respuesta del api
//
Api.interceptors.response.use(
    (resp) =>
    {
        // Comprobar las cabeceras de respuesta
        //
        // TODO: Cabecera personalizada 'Version'.
        // Esta cabecera se añade en la respuesta del back
        // Lo que se pretende es recargar la pagina y limpiar cache si se ha desplegado una nueva version
        // evitando que el usuario tenga que hacer CTRL+F5
        //
        //console.log(resp.headers['Version'], resp.headers['Version'] !== localStorage.getItem('version'));

        if (resp.headers['Version'] !== localStorage.getItem('Version')) {
            //console.log('location....',window.location)
            localStorage.setItem('Version', resp.headers['Version']);
            //window.location.href = window.location.origin;
            //window.location.reload();
        }
        return resp;
    }, (error) =>
    {
        // Cualquier codigo de estado en el rango 2xx provoca que se dispare
        // Tratar el error response
        //
        console.log('error response...',error.message);
        return Promise.reject(error);
    }
);

export default Api;
