<template>
    <v-container>
        <h2 class="mb-5">Gestión de entregas</h2>

        <!-- Sede -->
        <v-row justify="center">
            <v-col cols="11" style="padding-bottom: 0">
                <v-container>
                    <!-- Check Param Sede -->
                    <select-sedes-component :default="defaultSede"
                                            where="entregas = 1"
                                            @select="onSelectSede"
                                            :disabled="disableSede"
                                            label="Sede de entrega" />
                </v-container>
            </v-col>
        </v-row>

        <!-- Tabla -->
        <v-row justify="center" class="pa-0" style="margin-top: -20px">
            <v-col cols="11">
                <v-container>
                    <!-- Tabla buscador -->
                    <table-component
                        :headers="headers"
                        :items="items"
                        @select="selectPedido"
                        justify="center"
                        :cargando="cargando"
                        class="pa-5"/>
                </v-container>
            </v-col>
        </v-row>

        <!-- Descripcion Pedido -->
        <v-row justify="center" class="">
            <v-col cols="11">
                <v-container>
                    <v-card>
                        <v-card-title>
                            Detalles del pedido
                        </v-card-title>
                        <v-card-text>
                            <v-row class="row">
                                <v-col cols="6" sm="6">
                                    <v-text-field
                                        hide-details
                                        v-model="datosPedido.name"
                                        label="Nombre"
                                        outlined
                                        dense
                                        readonly
                                        filled>
                                    </v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        hide-details
                                        v-model="datosPedido.tipoEntrega"
                                        label="Tipo de entrega"
                                        outlined
                                        dense
                                        readonly
                                        filled>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="row">
                                <v-col cols="12">
                                    <v-text-field
                                        hide-details
                                        v-model="datosPedido.sede"
                                        label="Sede"
                                        maxlength="9"
                                        outlined
                                        dense
                                        readonly
                                        filled>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" sm="2" >
                                    <v-text-field
                                        hide-details
                                        v-model="datosPedido.fecha"
                                        label="Fecha de solicitud"
                                        outlined
                                        dense
                                        readonly
                                        filled>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2" sm="2" >
                                     <v-text-field
                                        hide-details
                                        v-model="datosPedido.fechaCita"
                                        label="Fecha de cita"
                                        outlined
                                        dense
                                        readonly
                                        filled>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2" sm="2" >
                                    <v-text-field
                                        hide-details
                                        v-model="datosPedido.horaCita"
                                        label="Hora de cita"
                                        outlined
                                        dense
                                        readonly
                                        filled>
                                    </v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        hide-details
                                        v-model="datosPedido.estadoPedido"
                                        label="Estado del pedido"
                                        outlined
                                        dense
                                        readonly
                                        filled>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-container>
            </v-col>
        </v-row>

        <!-- Ha seleccionado un pedido -->
        <div>
            <v-row justify="center" class="mx-9">
                <!-- Terminal Actual -->
                <v-col cols="6">
                    <v-container>
                        <v-card>
                            <v-card-title>
                                Terminal Actual
                            </v-card-title>
                            <v-card-text>
                              <v-row class="row">
                                  <v-col>
                                      <v-text-field v-model="datosPedido.marcaAntiguo"
                                          label="Marca"
                                          outlined
                                          hide-details
                                          dense
                                          filled
                                          readonly>
                                      </v-text-field>
                                  </v-col>
                              </v-row>
                              <v-row class="row">
                                  <v-col>
                                      <v-text-field
                                          v-model="datosPedido.modeloAntiguo"
                                          label="Modelo"
                                          outlined
                                          hide-details
                                          dense
                                          readonly
                                          filled>
                                      </v-text-field>
                                  </v-col>
                              </v-row>
                              <v-row class="row">
                                  <v-col>
                                      <v-text-field
                                          v-model="datosPedido.imeiAntiguo"
                                          label="IMEI"
                                          outlined
                                          dense
                                          readonly
                                          filled>
                                      </v-text-field>
                                  </v-col>
                              </v-row>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-col>

                <!-- Terminal Nuevo -->
                <v-col cols="6">
                    <v-container>
                        <v-card>
                            <v-card-title>
                                Terminal Nuevo
                            </v-card-title>
                            <v-card-text>
                                <v-row class="row">
                                    <v-col>
                                        <v-text-field
                                            v-model="datosPedido.marca"
                                            label="Marca"
                                            outlined
                                            hide-details
                                            dense
                                            filled
                                            readonly>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="row">
                                    <v-col>
                                        <v-text-field
                                            v-model="datosPedido.modelo"
                                            label="Modelo"
                                            outlined
                                            hide-details
                                            dense
                                            readonly
                                            filled>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                    <v-row class="row">
                                        <v-col>
                                            <v-text-field
                                                v-if="datosPedido.codigoEstado===this.$utils.FINALIZADO || datosPedido.idEstadoPedido == null"
                                                v-model="datosPedido.imei"
                                                label="IMEI"
                                                outlined
                                                dense
                                                readonly
                                                filled>
                                            </v-text-field>
                                            <v-text-field
                                                v-else v-model="datosPedido.imei"
                                                label="IMEI"
                                                :rules="[rules.obligatorio,rules.imei]"
                                                outlined
                                                dense
                                                append-icon="mdi-file-edit-outline">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-col>
            </v-row>

            <!-- Boton Finalizar Pedido y checkbox Entega terminal actual-->
            <v-row justify="center" class="pa-0">
                <v-col cols="5" sm="5" offset-md="1">
                    <!-- Pedido Finalizado -->
                    <v-row class="row pl-10 subtitle-1 text--disabled"
                           style="align-items:center">
                      <v-checkbox
                          outlined
                          :disabled="datosPedido.codigoEstado === null || datosPedido.codigoEstado === $utils.FINALIZADO"
                          v-model="datosPedido.checkDevuelto">
                      </v-checkbox>
                      <div>
                        Entrega terminal actual
                      </div>
                    </v-row>
                </v-col>
                <v-col cols="5" sm="5" offset-md="1">
                    <!-- Muestra Boton Finalizar en pedidos en curso -->
                    <div v-if="datosPedido.codigoEstado === $utils.EN_CURSO_SEDE">
                        <v-btn
                            color="primary"
                            type="submit"
                            :disabled="!checkFormulario"
                            large
                            @click=onClick()>
                            Finalizar
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>

        <!-- modal Verificar Accion -->
        <dialog-component
            ref="dialog"
            :width="600"
            @close="onCloseDialog"
            titulo="Finalizar entrega"
            yes="Aceptar"
            visibleYes
            no="Cancelar"
            visibleNo
        >
            <template v-slot>
                <v-container>
                    <h3>¿Desea finalizar la petición?</h3>
                </v-container>
            </template>
        </dialog-component>

        <!-- Overlay -->
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                :size="60"
                :width="6"
                color="primary"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
    import TableComponent from '@/components/app/TableComponent.vue';
    import DialogComponent from '@/components/app/DialogComponent.vue';
    import SelectSedesComponent from "@/components/app/SelectSedesComponent";
    import Pedido from "@/apis/Pedido";
    import Terminal from "@/apis/Terminal";

    export default {
      name: "SeguimientoView",
      components: {
            TableComponent,
            DialogComponent,
            SelectSedesComponent,
        },
      data() {
        return {
                datosPedido :{
                    name: null,
                    tipoEntrega: null,
                    sede: null,
                    fecha: null,
                    horaCita: null,
                    fechaCita:null,
                    estadoPedido: null,
                    imei:null,
                    marca:null,
                    modelo:null,
                    imeiAntiguo:null,
                    marcaAntiguo:null,
                    modeloAntiguo:null,
                    idEstadoPedido: null,
                    codigoEstado:null,
                    id_terminal_nuevo:null,
                    adquirirAntiguo:false,
                    checkDevuelve:false,
                    checkDevuelto:false,
                },
                headers :
                        [
                            {
                                text: "Solicitante",        // Titulo que se mostrara en la columna
                                value: "nombreCompleto",     // Clave de los item del array de datos al cual se asociara el titulo
                                align: "left",            // (Opcional) Para alinear el texto
                                sortable: true,            // (Opcional) Para permitir que pueda ser ordenado de menor a mayor y viceversa al seleccionar la columna
                                filterable: true,          // (Opcional) Para que el buscador busque o no en los datos de esta columna

                            },
                            {
                                text: "Fecha de solicitud",        // Titulo que se mostrara en la columna
                                value: "fecha_crea",     // Clave de los item del array de datos al cual se asociara el titulo
                                sortable: true,            // (Opcional) Para permitir que pueda ser ordenado de menor a mayor y viceversa al seleccionar la columna
                                filterable: true,        // (Opcional) Para que el buscador busque o no en los datos de esta columna
                                align:"center"
                            },
                            {
                                text: "Fecha de cita",        // Titulo que se mostrara en la columna
                                value: "fechaCita",     // Clave de los item del array de datos al cual se asociara el titulo
                                sortable: true,            // (Opcional) Para permitir que pueda ser ordenado de menor a mayor y viceversa al seleccionar la columna
                                filterable: true,        // (Opcional) Para que el buscador busque o no en los datos de esta columna
                                align:"center"
                            },{
                                text: "Estado del pedido",        // Titulo que se mostrara en la columna
                                value: "estado.descripcion",     // Clave de los item del array de datos al cual se asociara el titulo
                                align: "left",            // (Opcional) Para alinear el texto
                                sortable: true,            // (Opcional) Para permitir que pueda ser ordenado de menor a mayor y viceversa al seleccionar la columna
                                filterable: true,          // (Opcional) Para que el buscador busque o no en los datos de esta columna
                            }
                    ],
                items :[],
                filaSeleccionada: null,
                mostrar: false,
                cargando: false,
                selectedSede: null,
                idPedidoParam: null,
                overlay:false,
                rules: {
                    obligatorio: value => !!value || 'Campo obligatorio',
                    imei: value => { return this.$utils.validaImei(value) || 'Imei no válido' },
                },
            };
      },
      computed: {
          disableSede() {
              if(this.$store.getters.user === null){
                  return;
              }
              return this.$store.getters.user.perfil.peso !== this.$acceso.ADM;
          },
          defaultSede() {
              if (this.$store.getters.user === null) {
                  return;
              }

              if (this.$route.params.param != null) {
                  return this.$route.params.sede;
              }
              else if (this.selectedSede !== null) {
                  return this.selectedSede.id;
              }
              else {
                  return this.$store.getters.user.id_sede;
              }
          },
          checkFormulario() {
              //Pedido Finalizado o no seleccionado
              if(this.datosPedido.codigoEstado === this.$utils.FINALIZADO || this.datosPedido.idEstadoPedido == null){
                return false;
              }
              //Entregar terminal antiguo
              if(this.datosPedido.adquirirAntiguo === false){
                return this.datosPedido.checkDevuelto && this.$utils.validaImei(this.datosPedido.imei);
              }
              return this.$utils.validaImei(this.datosPedido.imei);
          },
      },
      methods: {
          async loadData() {
              this.overlay = true;
              try {
                  // Recibo id pedido y sede por parámetros
                  if(this.$route.params.param != null){
                      this.idPedidoParam = this.$route.params.param;
                  }
                  else {
                    this.idPedidoParam = null;
                  }

                  // Obtener los datos pedidos con recogida en sede y finalizados
                  const params = {
                      where: "envio_domicilio = 0 AND id_sede_entrega = "
                          + this.defaultSede
                          + (this.idPedidoParam !=null ? " AND id = " + this.idPedidoParam : ''),
                      eager: {
                          usuario: null,
                          envio: null,
                          terminalPedidos: null,
                          estado: "codigo IN ('" + this.$utils.EN_CURSO_SEDE + "','" + this.$utils.FINALIZADO + "')",
                          "envio.citas": null,
                          citas: null,
                      },
                  };
                  // Mostrar pedidos
                  this.items = await Pedido.getPedido(params);

                  this.items.forEach(element => {
                      element.nombreCompleto = element.usuario.name;
                      element.fecha_crea = this.$utils.fechaFormato(element.fecha_crea, 'DD/MM/YYYY');
                      element.fechaCita = this.$utils.fechaFormato(element.citas[0].fecha, "DD/MM/YYYY");
                      element.tipoEntrega = "Recogida en Sede";
                  });

                  // Autoselecciona pedido si vengo desde datos empleados
                  if(this.$route.params.param != null){
                      await this.selectPedido(this.items[0]);
                  }
              } catch (e) {
                  console.log(e)
                  this.$toast.error(e.message);
              }
              this.overlay = false;
          },
          async onSelectSede(value) {
              this.selectedSede = value;
              this.overlay = true;
              this.clearData();
              await this.loadData();
              this.overlay = false;
          },
          async selectPedido(value) {
              //Check si vuelve a seleccionar el mismo pedido de la tabla
              if(value === this.filaSeleccionada) {
                     return;
              }
              this.overlay = true;
              this.clearData();

              //Obtener terminales del usuario seleccionado y sede
              await this.obtenerTerminalUsuarios(value);
              this.obtenerSede(value);

              this.filaSeleccionada = value;

              //Asignar valores a datosPedido
              this.datosPedido.name  = value.nombreCompleto;
              this.datosPedido.fecha = value.fecha_crea;
              this.datosPedido.horaCita = value.citas[0].hora;
              this.datosPedido.fechaCita = this.$utils.fechaFormato(value.citas[0].fecha, "DD/MM/YYYY");
              this.datosPedido.tipoEntrega = value.tipoEntrega;
              this.datosPedido.sede = value.sede;
              this.datosPedido.estadoPedido  = value.estado.descripcion;
              this.datosPedido.idEstadoPedido = value.estado.id;
              this.datosPedido.codigoEstado = value.estado.codigo;
              this.datosPedido.adquirirAntiguo = value.adquirir_antiguo === 1;
              this.datosPedido.entregadoAntiguo = value.entregado_antiguo === 1;

              //Comprobar estado del pedido para asignar el valor del checkbox
              this.datosPedido.checkDevuelto = this.datosPedido.entregadoAntiguo;

              // Obtener Id Terminal Nuevo Asignado
              let id_terminal_nuevo = 0;
              value.terminal_pedidos.forEach(element => {
                  if(element.id_terminal_nuevo != null) {
                      id_terminal_nuevo = element.id_terminal_nuevo;
                      this.datosPedido.id_terminal_nuevo= id_terminal_nuevo;
                  }
              });

              //Obtener datos de terminal antiguo y nuevo
              if (value.terminalusuarios) {
                  value.terminalusuarios.forEach(element => {
                      if (element.id === id_terminal_nuevo) {
                          this.datosPedido.imei = element.imei;
                          this.datosPedido.marca = element.terminal.marca;
                          this.datosPedido.modelo = element.terminal.modelo;
                      }
                      else{
                          this.datosPedido.imeiAntiguo = element.imei;
                          this.datosPedido.marcaAntiguo = element.terminal.marca;
                          this.datosPedido.modeloAntiguo = element.terminal.modelo;
                      }
                  })
              }
              this.overlay = false;
          },
          async obtenerTerminalUsuarios(value){
              const params = {
                  where: "id_usuario = " + value.id_usuario,
              };
              value.terminalusuarios = await Terminal.getTerminalUsuario(params);
          },
          obtenerSede(value){
              value.sede = this.selectedSede.descripcion;
          },
          async onClick(){
            try {
              if (!this.$utils.validaImei(this.datosPedido.imei)) {
                return;
              }

              // Comprobar que el imei informado es del modelo correcto
              //
              await Terminal.validaTerminalImei(this.datosPedido);

              this.$refs.dialog.mostrar = true;
            }
            catch (e) {
              this.$toast.error(e.message);
            }
          },
          clearData(){
              this.datosPedido.name  = null;
              this.datosPedido.fecha = null;
              this.datosPedido.fechaCita = null;
              this.datosPedido.horaCita = null;
              this.datosPedido.tipoEntrega = null;
              this.datosPedido.sede = null;
              this.datosPedido.estadoPedido = null;
              this.datosPedido.idEstadoPedido = null;
              this.datosPedido.imei = null;
              this.datosPedido.codigoEstado = null;
              this.datosPedido.adquirirAntiguo=false;
              this.datosPedido.checkDevuelve = false;
              this.datosPedido.imei = null;
              this.datosPedido.marca = null;
              this.datosPedido.modelo = null;
              this.datosPedido.imeiAntiguo = null;
              this.datosPedido.marcaAntiguo = null;
              this.datosPedido.modeloAntiguo = null;
              this.datosPedido.checkDevuelto = false;

              this.filaSeleccionada = null;
          },
          async onCloseDialog(value) {
              try {
                  if (value !== 'YES') {
                      return;
                  }

                  this.overlay = true;

                  //Actualizar estado pedido y Actualizar Imei asignado
                  let params = {
                      idPedido: this.filaSeleccionada.id,
                      idTerminal:this.datosPedido.id_terminal_nuevo,
                      imei: this.datosPedido.imei
                  };
                  await Pedido.finalizarSeguimientoSede(params);

                  this.$route.params.param = null;
                  await this.loadData();

                  this.clearData();

                  this.$toast.success("El pedido ha sido completado");
              }
              catch (e) {
                  this.overlay = false;
                  this.$toast.error(e.message);
              }
               this.overlay = false;
          },
      }
    }
</script>
