<template>
    <v-container>
        <h2 class="mb-5">
            Petición cambio de terminal
        </h2>

        <v-row justify="center" class="pa-0 ma-0">
            <v-col cols="6">
                <v-img alt="Estado" class="v-img" contain height="50px" :src="iconoEstado"></v-img>

                {{textoEstado}}
            </v-col>
        </v-row>

        <template>
            <!-- Datos de usuario
            -->
            <v-row justify="center" class="pa-0">
                <v-col cols="8">
                    <datos-usuario-component :id-usuario="datosUsuario.id"></datos-usuario-component>
                </v-col>
            </v-row>

            <!-- Adquirir terminal antiguo
            -->
            <!--
            <v-container class="pa-5">
                <v-row justify="center">
                    <v-radio-group row
                                   v-model="adquirirAntiguo"
                                   :disabled="disableAll"
                                   @change="onChangeAdquirirActual">
                        <v-radio
                            :label="$t('quiere_term.si')"
                            :value="1"
                        ></v-radio>
                        <v-radio
                            :label="$t('quiere_term.no')"
                            :value="0"
                        ></v-radio>
                    </v-radio-group>
                </v-row>
            </v-container>
            -->
            <!-- Mas terminales
            -->
            <!--
            <mas-terminales-component :disabled="disableAll"
                                      class="pa-5"
                                      :datos="actuales"
                                      @select="onSelectMasTerminales"/>
            -->
            <!-- Selector de entrega en sede o envio domicilio
            -->
            <!--
            <v-container>
                <v-row justify="center">
                    <v-radio-group row
                                   v-model="envioDomicilio"
                                   @change="clearData"
                                   :disabled="disableAll"
                                   :hint="$t('tipo_envio.hint')"
                                   persistent-hint>
                        <v-radio
                            :label="$t('tipo_envio.sede')"
                            :value="0"
                        ></v-radio>
                        <v-radio
                            :label="$t('tipo_envio.domicilio')"
                            :value="1"
                        ></v-radio>
                    </v-radio-group>
                </v-row>
            </v-container>
            -->
            <!-- Entrega sede. Selector de sede y calendario
            -->
            <v-container v-if="envioDomicilio === 0" class="pt-8">
                <v-row justify="center" class="pa-0">
                    <v-col cols="8">
                        <select-sedes-component :default="defaultSede"
                                                label="Sede de entrega"
                                                where="entregas = 1"
                                                @select="onSelectSede"
                                                :disabled="disableAll"/>
                    </v-col>
                </v-row>

                <!-- Seleccion de cita
                -->
                <v-row justify="center">
                    <v-col cols="8">
                        <v-banner elevation="2">
                            <v-row>
                                <v-col cols="4" sm="4" style="margin-top: 10px;">
                                    <v-btn class="float-left"
                                           style="height: 40px"
                                           outlined
                                           color="primary"
                                           @click="onOpenSeleccionarCita"
                                           :disabled="disableAll"
                                    >
                                        Seleccionar cita
                                        <v-icon right dark>
                                            mdi-calendar-month
                                        </v-icon>
                                    </v-btn>
                                </v-col>

                                <v-col cols="8" style="margin-top: 10px;padding-left: 0;">
                                    <v-text-field
                                        persistent-placeholder
                                        class="text-h6"
                                        v-model="cita"
                                        placeholder="Seleccione un día para la cita"
                                        label="Cita"
                                        outlined
                                        readonly
                                        dense
                                        filled
                                        @change="hayCambios = true"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-banner>
                    </v-col>
                </v-row>
            </v-container>

            <!-- Envio domicilio
            -->
            <v-container v-if="envioDomicilio === 1" class="pa-5">
                <v-row justify="center" class="pa-0">
                    <v-col cols="8">
                        <select-motivo-envio-component v-model="selectedMotivo"
                                                       ref="motivoEnvio"
                                                       label="Motivo de envío"
                                                       @select="onSelectMotivo"
                                                       :disabled="disableAll"/>
                    </v-col>
                </v-row>

                <v-row justify="center" class="pa-0 ma-0">
                    <v-col cols="8">
                        <datos-envio-component v-model="datosEnvio"
                                               ref="datosEnvio"
                                               @select="onSelectDatosEnvio"
                                               :disabled="disableAll"/>
                    </v-col>
                </v-row>

                <!-- Terminos de uso y privacidad
                -->
                <v-row justify="center" class="pa-0 ma-0" v-if="!disableAll">
                    <v-col cols="6">
                        <politica-component @change="onChangePolitica"></politica-component>
                    </v-col>
                </v-row>
            </v-container>

            <!-- Boton de confirmacion
            -->
            <v-container>
                <v-row justify="center" class="my-2">
                    <v-btn x-large color="primary" @click="confirmarPedido" :disabled="!politicaOk || !hayCambios" v-show="!disableAll">
                        {{modificarSolicitud ? 'Modificar solicitud' : 'Enviar solicitud'}}
                        <v-icon right dark>
                            mdi-send
                        </v-icon>
                    </v-btn>
                </v-row>
            </v-container>

        </template>

        <!-- Confirmación del pedido
        -->
        <dialog-component :titulo="envioDomicilio === 1 ? 'Confirmar envío domicilio' : 'Confirmar entrega en sede'"
                          :width="800"
                          ref="dialogConfirmacion"
                          @close="onCloseDialogConfirmar"
                          yes="Confirmar"
                          no="Volver"
                          :visible-no="true"
                          :visible-yes="true"
        >
            <template v-slot>
                <v-container v-if="envioDomicilio === 1">
                    <h2>
                        {{datosEnvio ? datosEnvio.nombre : ''}} {{datosEnvio ? datosEnvio.apellidos : ''}}
                        <br><br>
                        {{datosEnvio ? datosEnvio.direccion : ''}}  {{datosEnvio ? datosEnvio.codpost : ''}}
                        <br><br>
                        {{datosEnvio ? datosEnvio.provincia : ''}}
                        <br><br>
                        {{datosEnvio ? datosEnvio.email : ''}}
                    </h2>
                </v-container>
                <v-container v-if="envioDomicilio === 0">
                    <h3>
                        {{datosUsuario ? datosUsuario.name : ''}}
                        <br><br>
                        {{selectedSede ? selectedSede.descripcion : ''}}
                        <br><br>
                        <strong>
                            {{cita}}
                        </strong>
                    </h3>
                </v-container>
            </template>
        </dialog-component>

        <!-- Mensaje pedido existe y no se puede modificar
        -->
        <dialog-component :titulo="existe.titulo"
                          :width="500"
                          ref="dialogExiste"
                          yes="Continuar"
                          visible-yes
        >
            <template v-slot>
                <v-container>
                    <h3>
                        {{ existe.mensaje }}
                    </h3>
                </v-container>
            </template>
        </dialog-component>

        <!-- Mensaje pedido rechazado ¿crear nuevo?
        -->
        <dialog-component titulo="Petición denegada"
                          :width="700"
                          ref="dialogNuevo"
                          @close="onCloseDialogNuevo"
                          yes="Continuar"
                          no="Volver"
                          visible-yes
                          visible-no
        >
            <template v-slot>
                <v-container>
                    <h3>
                        Su petición de envío a domicilio ha sido denegada
                        <br>
                        Si continúa podrá crear una nueva petición
                    </h3>
                    <v-textarea
                                :value="motivoRechazo"
                                label="Motivo de rechazo"
                                rows="3"
                                outlined
                                readonly
                                dense
                                filled
                                auto-grow
                                hide-details
                                class="pa-4"
                            ></v-textarea>
                </v-container>
            </template>
        </dialog-component>

        <!-- Confirmación adquiere terminal
        -->
        <dialog-component titulo="Adquirir terminal actual"
                          :width="700"
                          ref="dialogAdquirir"
                          @close="onCloseDialogAdquirir"
                          yes="Aceptar"
                          no="Cancelar"
                          :visible-no="false"
                          :visible-yes="true"
        >
            <template v-slot>
                <v-container>
                    <h2>
                        {{$t('quiere_term.aviso')}}
                    </h2>
                </v-container>
            </template>
        </dialog-component>

        <!-- Modal seleccion de cita
        -->
        <modal-calendar-component :sede="sede"
                                  @close="onCloseModalCita"
                                  ref="modalCal"/>

        <!-- Overlay
        -->
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                :size="60"
                :width="6"
                color="primary"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
    import SelectMotivoEnvioComponent from "@/components/app/SelectMotivoEnvioComponent";
    import DatosUsuarioComponent from "@/components/user/DatosUsuarioComponent";
    //import MasTerminalesComponent from "@/components/user/MasTerminalesComponent";
    import SelectSedesComponent from "@/components/app/SelectSedesComponent";
    import DatosEnvioComponent from "@/components/user/DatosEnvioComponent";
    import DialogComponent from "@/components/app/DialogComponent";
    import Pedido from "@/apis/Pedido";
    import PoliticaComponent from "@/components/user/PoliticaComponent";
    import ModalCalendarComponent from "@/components/citas/ModalCalendarComponent";
    import usuario from "@/apis/Usuario";

    export default {
        name: "RenovarView",
        components: {
            ModalCalendarComponent,
            PoliticaComponent,
            DialogComponent,
            DatosEnvioComponent,
            SelectSedesComponent,
            //MasTerminalesComponent,
            DatosUsuarioComponent,
            SelectMotivoEnvioComponent
        },
        data() {
            return {
                usuarioActivo: null,
                selectedMotivo: null,
                selectedSede: null,
                selectedCita: null,
                selectedPolitica: null,
                adquirirAntiguo: 0,
                envioDomicilio: 0,
                datosUsuario: {},
                masTerminales: [],
                datosEnvio: null,
                confirmar: false,
                pedido: null,
                existe: {
                    titulo: null,
                    mensaje: null,
                },
                actuales: [],
                textoEstado: '',
                iconoEstado: '',
                overlay: false,
                modificarSolicitud: false,
                disableAll: false,
                defaultSede: this.$store.getters.user.id_sede,
                hayCambios: false,
                motivoRechazo:false,
            };
        },
        computed: {
            sede() {
                return this.selectedSede;
            },
            cita() {
                if (this.selectedCita){
                    const fecha = this.$utils.fechaFormato(this.selectedCita.fecha, 'D MMMM, YYYY');
                    const ini = this.selectedCita.hora;
                    const fin = this.selectedSede.slot === 0 ? this.selectedSede.hora_hasta : this.$utils.sumaHoras(ini, 1);

                    return fecha + " de " + ini + " a " + fin + " horas";
                }
                return '';
            },
            politicaOk() {
                if (this.envioDomicilio === 1)
                    return !!this.selectedPolitica;
                else
                    return true;
            },
        },
        watch: {
            datosEnvio(){
                return this.datosEnvio;
            },
        },
        async mounted() {
            await this.usuarioActual();
            await this.loadData();
            this.hayCambios = false;
        },
        methods: {
            async usuarioActual() {
              // Recibo id usuario por parámetros en caso de venir desde la opción de empleados y
              // un administrador va a crear una petición a otro usuario
              //
              try {
                if (this.$route.params.idUsuario != null) {
                  this.usuarioActivo = await usuario.getUsuario(this.$route.params.idUsuario);
                  this.datosUsuario = this.usuarioActivo;
                  this.defaultSede = this.usuarioActivo.sede.id;
                  this.selectedSede = this.usuarioActivo.sede;
                } else {
                  this.usuarioActivo = this.$store.getters.user;
                }
              } catch (e) {
                this.$toast.error(e.message);
                this.usuarioActivo = this.$store.getters.user;
                return;
              }
            },
            async loadData() {
              // Comprobar que la sede del usuario tiene abierto el periodo de cambio
              // En caso contrario bloquear y enviar mensaje
              //
              if (!this.$utils.fechaEnRango(this.usuarioActivo.sede.fecha_desde, this.usuarioActivo.sede.fecha_hasta)) {
                this.disableAll = true;
                this.$toast.error('El plazo está cerrado')
                return;
              }

              this.modificarSolicitud = false;
              this.overlay = true;

              this.selectedMotivo = null;
              this.selectedCita = null;
              this.selectedPolitica = null;
              this.adquirirAntiguo = 0;
              this.envioDomicilio = 0;
              this.masTerminales = [];
              this.actuales = [];
              this.datosEnvio = null;
              this.pedido = null;

              try {
                const params = {
                  where: "id_usuario = " + this.usuarioActivo.id,
                  eager: {
                    estado:
                        "codigo NOT IN ('" +
                        this.$utils.BORRADO + "','" +
                        this.$utils.CANCELADO + "')",
                    sede: null,
                    envio: null,
                    citas: null,
                    terminalPedidos: null,
                  },
                };
                this.pedido = await Pedido.getPedido(params);

                if (this.pedido.length <= 0) {
                  this.estadoValido();
                  this.overlay = false;
                  return;
                }

                this.modificarSolicitud = true;

                this.estadoValido(this.pedido[0]);

                // Si hay pedido activo asignar los datos a cada componente
                //
                this.selectedSede = this.pedido[0].sede;
                this.defaultSede = this.selectedSede != null ? this.selectedSede.id : null;
                this.adquirirAntiguo = this.pedido[0].adquirir_antiguo;
                this.envioDomicilio = this.pedido[0].envio_domicilio;
                this.actuales = this.pedido[0].terminal_pedidos;
                this.selectedCita = this.pedido[0].citas ? this.pedido[0].citas[0] : null;
                this.datosEnvio = this.pedido[0].envio;
                this.selectedMotivo = {
                  codigo: this.pedido[0].motivo_domicilio,
                  comentario: this.pedido[0].comentario
                };

                this.motivoRechazo = this.pedido[0].motivo_rechazo ? this.pedido[0].motivo_rechazo : null;

                this.$forceUpdate();
                this.overlay = false;
              } catch (e) {
                this.overlay = false;
                this.$toast.error(e.message);
              }
            },
            estadoValido(pedido = null) {
                this.disableAll = false;

                if (pedido === null){
                    this.textoEstado = 'Nuevo';
                    this.iconoEstado = require('@/assets/icono-nuevo.png');
                    return;
                }

                this.textoEstado = pedido.estado.descripcion;
                this.iconoEstado = pedido.estado.icono ? require("@/assets/"+pedido.estado.icono) : require('@/assets/icono-nuevo.png');

                switch (pedido.estado.codigo) {
                    case this.$utils.ENVIADO:
                    case this.$utils.EN_CURSO_ZELERIS:
                        this.existe.titulo = "Petición en curso"
                        this.existe.mensaje = "En trámite por la compañía de transporte. No se puede modificar";
                        this.$refs.dialogExiste.mostrar = true;
                        this.disableAll = true;
                        break;
                    case this.$utils.FINALIZADO:
                        this.existe.titulo = "Petición finalizada"
                        this.existe.mensaje = "Solo se permite un cambio de terminal";
                        this.$refs.dialogExiste.mostrar = true;
                        this.disableAll = true;
                        break;
/* Esto evita que se pueda modificar un pedido en sede el mismo dia de la cita ***
                    case this.$utils.EN_CURSO_SEDE:
                        if (this.$utils.esHoyCita(pedido.citas[0].fecha)){
                            this.existe.titulo = "Petición en curso"
                            this.existe.mensaje = "La cita es hoy. No se puede modificar";
                            this.$refs.dialogExiste.mostrar = true;
                            this.disableAll = true;
                        }
                        break;
*/
                    case this.$utils.RECHAZADO:
                        this.$refs.dialogNuevo.mostrar = true;
                        break;
                }
            },
            onSelectMotivo(value) {
                this.hayCambios = true;
                this.selectedMotivo = value;
            },
            onSelectMasTerminales(value){
                this.masTerminales = value;
                this.hayCambios = true;
            },
            onSelectCita(value) {
                this.selectedCita = value;
            },
            onSelectSede(value) {
                this.hayCambios = true;
                this.selectedCita = null;
                this.selectedSede = value;
            },
            onSelectDatosEnvio(value) {
                this.hayCambios = true;
                this.datosEnvio = value;
            },
            onChangePolitica(value) {
                this.hayCambios = true;
                this.selectedPolitica = value;
            },
            onChangeAdquirirActual(value){
                this.hayCambios = true;
                if (value === 1){
                    this.$refs.dialogAdquirir.mostrar = true;
                }
            },
            async onCloseModalCita(value) {
                this.hayCambios = true;
                this.selectedCita = value;
            },
            async onCloseDialogConfirmar(value) {
                try{
                    if (value !== 'YES') {
                        return;
                    }

                    this.overlay = true;

                    const params = {
                        idPedido: this.pedido.length > 0 ? this.pedido[0].id : null,
                        datosUsuario: this.datosUsuario,
                        adquirirAntiguo: this.adquirirAntiguo,
                        masTerminales: this.masTerminales,
                        envioDomicilio: this.envioDomicilio,
                        motivoEnvio: this.selectedMotivo,
                        datosEnvio: this.datosEnvio,
                        cita: this.selectedCita,
                        sede: this.selectedSede,
                    };
                    await Pedido.newPedido(params);

                    await this.loadData();

                    window.scrollTo(0,0);
                    this.$toast.success('Petición creada correctamente');
                    this.hayCambios = false;
                    this.overlay = false;

                    this.$router.push({name: "home"})
                        .catch(() => {
                            // ignorar error
                        });
                }
                catch (e) {
                    this.overlay = false;
                    this.$toast.error(e.message);
                }
            },
            async onCloseDialogNuevo(value) {
                try{
                    if (value !== 'YES'){
                        this.disableAll = true;
                        return;
                    }

                    this.overlay = true;

                    //Actualizar estado pedido (borrar)
                    let params = {
                        idPedido: this.pedido[0].id,
                    };
                    await Pedido.deletePedido(params);

                    //Limpiar y recargar datos
                    this.clearData();
                    await this.loadData();

                    window.scrollTo(0,0);
                    this.modificarSolicitud = false;
                    this.$forceUpdate();
                    this.overlay = false;
                }
                catch (e) {
                    this.overlay = false;
                    this.$toast.error(e.message);
                }
            },
            onCloseDialogAdquirir(){
                //this.adquirirAntiguo = (value === "YES" ? 1 : 0);
                // No se permite adquirir el terminal actual por ahora
                this.adquirirAntiguo = 0;
            },
            clearData() {
                this.hayCambios = true;
                this.selectedMotivo = null;
                this.datosEnvio = null;
                this.selectedCita = null;
                this.selectedSede = null;
                this.defaultSede = this.$store.getters.user.id_sede;
            },
            confirmarPedido() {
                // Validar datos de envio
                //
                if (this.envioDomicilio === 1) {
                    const valMotivo = this.$refs.motivoEnvio.validarMotivoEnvio();
                    const valEnvio = this.$refs.datosEnvio.validarDatosEnvio();

                    if(!valEnvio || !valMotivo){
                        this.$toast.error('Revise los datos y vuelva a intentarlo');
                        return;
                    }
                }
                else {
                    if (!this.cita) {
                        this.$toast.error('Debe seleccionar una fecha');
                        return;
                    }
                }

                // Abrir formulario de confirmación
                //
                this.$refs.dialogConfirmacion.mostrar = true;
            },
            onOpenSeleccionarCita() {
                this.$refs.modalCal.mostrar = true;
                this.$refs.modalCal.recargaSede();
            },
        },
    }
</script>

