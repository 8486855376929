<template>
  <v-container fluid>
    <v-data-iterator
        :items="items"
        :search="search"
        :items-per-page.sync="itemsPerPage"
        hide-default-footer
        disable-pagination
    >
      <template v-slot:header>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                hide-details
                outlined
                class="customBackgroundColor mb-4"
                dense
            ></v-text-field>
          </v-col>
          <v-col v-if="excel_fields" cols="3">
            <v-btn color="primary">
              <download-excel
                  :fields="excel_fields"
                  :data="items"
                  :name="'informe_estado'">
                Descargar excel
                <v-icon>mdi-file-excel-outline</v-icon>
              </download-excel>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:default="props">
          <v-row>
            <v-col v-for="item in props.items"
                   :key="item.sede_entrega"
                   cols="3">
              <v-card>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-card-title class="text-left text-truncate text-no-wrap"
                                  style="display: block"
                                  :style="item.id === 0 ? styleObject : ''"
                                  v-bind="attrs"
                                  v-on="on">
                      {{ item.sede_entrega }}
                    </v-card-title>
                  </template>
                  <span>{{ item.sede_entrega }}</span>
                </v-tooltip>

                <v-divider></v-divider>

                <v-list dense :style="item.id === 0 ? styleObject : ''">
                  <!--
                  <v-row>
                    <v-col/>
                    <v-col class="font-weight-bold">
                      Sede
                    </v-col>
                    <v-col class="font-weight-bold">
                      Domicilio
                    </v-col>
                  </v-row>
                  -->
                  <v-row class="no-gutters mb-0">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="">Pendiente</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <p class="ma-0" style="text-align: end;">
                            {{ item.sede_pendiente }}
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <!--
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <p class="ma-0" style="text-align: end;">
                            {{ item.domicilio_pendiente }}
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    -->
                  </v-row>

                  <v-row class="no-gutters mb-0">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="">En curso</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <p class="ma-0" style="text-align: end;">
                            {{ item.sede_en_curso }}
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <!--
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <p class="ma-0" style="text-align: end;">
                            {{ item.domicilio_en_curso }}
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    -->
                  </v-row>

                  <v-row class="no-gutters mb-0">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="">Entregado</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <p class="ma-0" style="text-align: end;">
                            {{ item.sede_entregado }}
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <!--
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <p class="ma-0" style="text-align: end;">
                            {{ item.domicilio_entregado }}
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    -->
                  </v-row>

                  <v-row class="no-gutters mb-0">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="">Cancelado</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <p class="ma-0" style="text-align: end;">
                            {{ item.sede_cancelado }}
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <!--
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <p class="ma-0" style="text-align: end;">
                            {{ item.domicilio_cancelado }}
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    -->
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="no-gutters mb-0">
                    <v-col>
                      <v-list-item>
                        <v-list-item-content class="">Total</v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <p class="ma-0" style="text-align: end;">
                            {{ item.sede_total }}
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <!--
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <p class="ma-0" style="text-align: end;">
                            {{ item.domicilio_total }}
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    -->
                  </v-row>

                </v-list>
              </v-card>
            </v-col>
          </v-row>

      </template>

    </v-data-iterator>
  </v-container>
</template>

<script>
import downloadExcel from "vue-json-excel";

export default {
  name: "EstadisticasComponent",
  components: {
    downloadExcel,
  },
  props: {
    items: Array,
    excel_fields: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      search: '',
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 1,
      sortBy: 'sede_entrega',
      styleObject: {
        fontWeight: 'bold',
      }
    }
  },

}
</script>

<style scoped>

</style>
